import React, { useEffect, useRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Seo from '../components/utils/Seo';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Cursor from '../components/utils/Cursor';
import Nav from '../components/modules/Nav';
import Loader from '../components/modules/Loader';
import Page from '../components/modules/Page';

function HomePageTemplate({ pageContext, transitionStatus, entry }: any) {
	const containerRef = useRef(null);

	useEffect(() => {
		window.history.replaceState({}, document.title);
		document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one');
		document.body.classList.add('bg-home');
	}, []);

	return (
		<>
			<Seo title="Home" />
			<LocomotiveScrollProvider
				options={
					{
						smooth: true,
						direction: 'horizontal',
						gestureDirection: 'both',
						tablet: {
							direction: 'vertical',
						}
					}
				}
				watch={
					[]
				}
				containerRef={containerRef}
			>
				<Header />
				<Cursor />
				<Nav />
				<main data-scroll-container ref={containerRef}>
					<Page
						pageContext={pageContext}
						transitionStatus={transitionStatus}
						entry={entry}
					/>
				</main>
			</LocomotiveScrollProvider>
			<Footer />
			<Loader transitionStatus={transitionStatus} />
		</>
	);
}

export default HomePageTemplate;
