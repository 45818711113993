import React from 'react';
import { FaFacebookF, FaInstagram} from 'react-icons/fa';
import IconButton from '../../components/ui/IconButton';

function Footer() {

	return (
		<>
			<ul className="site-footer__social">
				<li>
					<IconButton
						className="icon-button--sm"
						url="https://www.facebook.com/roderickcoxconductor/"
						opensNewTab
					>
						<FaFacebookF />
					</IconButton>
				</li>
				<li>
					<IconButton
						className="icon-button--sm"
						url="https://www.instagram.com/roderickcoxconductor/"
						opensNewTab
					>
						<FaInstagram />
					</IconButton>
				</li>
			</ul>
		</>
	);
}

export default Footer;
