import React from 'react';
import { IPerformance } from 'types/schedule';
import Button from '../ui/Button';

interface Props {
	currentItem: IPerformance;
}

function PerformanceDetails({ currentItem }: Props) {
	return (
		<article className="schedule__info">
			<img className="performance__image" src={currentItem.imgSrc} alt={`${currentItem.title} post image`} />
			<h3 className="performance__title performance__title--sm">{currentItem.title}</h3>
			{currentItem.url && <Button url={currentItem.url} className="button button--white" opensNewTab>More info</Button>}
		</article>
	);
}

export default PerformanceDetails;
