import React, { useEffect, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import Hero from '../../components/modules/Hero';
import Schedule from '../../components/modules/Sechdule';
import About from '../../components/modules/About';
import Media from '../../components/modules/Media';
import Initiative from '../../components/modules/Initiative';
import Contact from '../../components/modules/Contact';

interface Props {
	pageContext: any;
	transitionStatus: any;
	entry: any;
}

function Page({ pageContext, transitionStatus, entry }: any) {
	const { scroll } = useLocomotiveScroll();
	let resizeTimer: ReturnType<typeof setTimeout>;
	const [aboutImgScale, setAboutImgScale] = useState(1);
	const [initiativeImgScale, setInitiativeImgScale] = useState(1);

	const resizeHandler = (winWidth: number) => {
		if (winWidth > 1024 && window.innerWidth < 1025) {
			location.reload();
			return;
		}

		if (winWidth < 1024 && window.innerWidth > 1024) {
			location.reload();
			return;
		}
	};

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();

			scroll.on('scroll', (args: any) => {
				const { currentElements, scroll } = args;

				setAboutImgScale(1.4 - scroll.x / 10000);
				setInitiativeImgScale(1.9 - (scroll.x) / 10000);
	
				Object.keys(currentElements).map((key) => {
					const section = currentElements[key].section;
	
					if (section === undefined) {
						return;
					}
	
					const bgColour = section.el.dataset.bgColour;
					const rect = section.el.getBoundingClientRect();
	
					if (rect.left < 50 && section.inView && bgColour !== undefined) {
						if (!document.body.classList.contains(`bg-${bgColour}`)) {
							document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one', 'bg-home');
							document.body.classList.add(`bg-${bgColour}`);
						}
					}
				});
			});
		}, 500);
	}, [scroll]);

	useEffect(() => {
		const { state } = entry;

		if (!state) {
			return;
		}

		if (scroll && state.anchorTo && transitionStatus === 'entering') {
			setTimeout(() => {
				scroll.scrollTo(state.anchorTo, { duration: 0, disableLerp: true });
			}, 500);
		}
	}, [scroll, entry]);

	useEffect(() => {
		const winWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => resizeHandler(winWidth), 350);
		});
	}, []);

	return (
		<>
			<Hero
				bgImgSrc={pageContext.acf.hero.background_image.localFile.childImageSharp.fluid.src}
				transitionStatus={transitionStatus}
			/>
			<Schedule title={pageContext.acf.schedule.title} />
			<About
				imgScale={aboutImgScale}
				title={pageContext.acf.about.title}
				heroImageSrc={pageContext.acf.about.hero_image.localFile.childImageSharp.fluid.src}
				intro={pageContext.acf.about.intro_1}
				content={pageContext.acf.about.intro_2}
				imageSrc={pageContext.acf.about.secondary_image.localFile.childImageSharp.fluid.src}
				quote={pageContext.acf.about.quote}
				quoteAuthor={pageContext.acf.about.quote_author}
			/>
			<Media />
			<Initiative
				imgScale={initiativeImgScale}
				title={pageContext.acf.roderick_cox_initiative.title}
				intro={pageContext.acf.roderick_cox_initiative.intro}
				pageLink={pageContext.acf.roderick_cox_initiative.page_link}
				imgSrc={pageContext.acf.roderick_cox_initiative.image.localFile.childImageSharp.fluid.src}
			/>
			<Contact
				title={pageContext.acf.contact.title}
				intro={pageContext.acf.contact.intro}
				contacts={pageContext.acf.contact.contacts}
			/>
		</>
	);
}

export default Page;
