import React from 'react';
import Anchor from '../../components/ui/Anchor';

interface Props {
	imgScale: number;
	title: string;
	heroImageSrc: string;
	intro: string;
	content: string;
	imageSrc: string;
	quote: string;
	quoteAuthor: string;
}

function About({ imgScale, title, heroImageSrc, intro, content, imageSrc, quote, quoteAuthor }: Props) {

	return (
		<section id="about" className="about" data-scroll-section data-bg-colour="black">
			<div className="container container--fluid">
				<div className="about__image about__image--hero">
					<div className="frame">
						<div className="wrapper" style={{ transform: `scale(${imgScale})` }}>
							<div
								className="inner"
								style={{
									backgroundImage: `url(${heroImageSrc})`,
								}}
							/>
						</div>
					</div>
				</div>
				<div className="about__content">
					<h2 className="about__title">{title}</h2>
					<p className="about__intro">{intro}</p>
					<p>
						{content}
						<br /> <br />
						<Anchor url="/about" className="button button--red">Read more</Anchor>
					</p>
				</div>
				<div className="about__quote">
					<p className="about__quote-text" data-scroll data-scroll-speed="1">
						<span>
							<svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.4655 35.5387C9.44154 35.5387 7.78554 35.1247 6.49754 34.2967C5.20954 33.4687 4.15154 32.4107 3.32354 31.1227C2.40354 29.5587 1.75954 27.9487 1.39154 26.2927C1.11554 24.5447 0.977539 23.1187 0.977539 22.0147C0.977539 17.5067 2.12754 13.4127 4.42754 9.7327C6.72754 6.0527 10.3155 3.0627 15.1915 0.762695L16.4335 3.2467C13.5815 4.4427 11.0975 6.3287 8.98154 8.9047C6.95754 11.4807 5.94554 14.1027 5.94554 16.7707C5.94554 17.8747 6.08354 18.8407 6.35954 19.6687C7.83154 18.4727 9.53354 17.8747 11.4655 17.8747C13.8575 17.8747 15.9275 18.6567 17.6755 20.2207C19.4235 21.7847 20.2975 23.9467 20.2975 26.7067C20.2975 29.2827 19.4235 31.3987 17.6755 33.0547C15.9275 34.7107 13.8575 35.5387 11.4655 35.5387ZM34.9255 35.5387C32.9015 35.5387 31.2455 35.1247 29.9575 34.2967C28.6695 33.4687 27.6115 32.4107 26.7835 31.1227C25.8635 29.5587 25.2195 27.9487 24.8515 26.2927C24.5755 24.5447 24.4375 23.1187 24.4375 22.0147C24.4375 17.5067 25.5875 13.4127 27.8875 9.7327C30.1875 6.0527 33.7755 3.0627 38.6515 0.762695L39.8935 3.2467C37.0415 4.4427 34.5575 6.3287 32.4415 8.9047C30.4175 11.4807 29.4055 14.1027 29.4055 16.7707C29.4055 17.8747 29.5435 18.8407 29.8195 19.6687C31.2915 18.4727 32.9935 17.8747 34.9255 17.8747C37.3175 17.8747 39.3875 18.6567 41.1355 20.2207C42.8835 21.7847 43.7575 23.9467 43.7575 26.7067C43.7575 29.2827 42.8835 31.3987 41.1355 33.0547C39.3875 34.7107 37.3175 35.5387 34.9255 35.5387Z" fill="#DC143C" />
							</svg>
							...{quote}...
							<svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M32.5569 0.859736C34.5809 0.859737 36.2369 1.27374 37.5249 2.10174C38.8129 2.92974 39.8709 3.98774 40.6989 5.27574C41.6189 6.83974 42.2629 8.44974 42.6309 10.1057C42.9069 11.8537 43.0449 13.2797 43.0449 14.3837C43.0449 18.8917 41.8949 22.9857 39.5949 26.6657C37.2949 30.3457 33.7069 33.3357 28.8309 35.6357L27.5889 33.1517C30.4409 31.9557 32.9249 30.0697 35.0409 27.4937C37.0649 24.9177 38.0769 22.2957 38.0769 19.6277C38.0769 18.5237 37.9389 17.5577 37.6629 16.7297C36.1909 17.9257 34.4889 18.5237 32.5569 18.5237C30.1649 18.5237 28.0949 17.7417 26.3469 16.1777C24.5989 14.6137 23.7249 12.4517 23.7249 9.69174C23.7249 7.11574 24.5989 4.99974 26.3469 3.34374C28.0949 1.68774 30.1649 0.859736 32.5569 0.859736ZM9.09692 0.859734C11.1209 0.859735 12.7769 1.27374 14.0649 2.10174C15.3529 2.92974 16.4109 3.98774 17.2389 5.27574C18.1589 6.83974 18.8029 8.44973 19.1709 10.1057C19.4469 11.8537 19.5849 13.2797 19.5849 14.3837C19.5849 18.8917 18.4349 22.9857 16.1349 26.6657C13.8349 30.3457 10.2469 33.3357 5.37092 35.6357L4.12892 33.1517C6.98092 31.9557 9.46492 30.0697 11.5809 27.4937C13.6049 24.9177 14.6169 22.2957 14.6169 19.6277C14.6169 18.5237 14.4789 17.5577 14.2029 16.7297C12.7309 17.9257 11.0289 18.5237 9.09692 18.5237C6.70492 18.5237 4.63492 17.7417 2.88692 16.1777C1.13892 14.6137 0.264921 12.4517 0.264922 9.69173C0.264922 7.11573 1.13892 4.99974 2.88692 3.34374C4.63492 1.68773 6.70492 0.859734 9.09692 0.859734Z" fill="#DC143C" />
							</svg>
						</span>
						<cite>
							{quoteAuthor}
						</cite>
					</p>
					<div className="about__image about__image--content">
						<div className="wrapper">
							<div className="inner" style={{ backgroundImage: `url(${imageSrc})` }} data-scroll data-scroll-speed="-0.5"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
