import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';
import PerformanceList from '../template-parts/PerformanceList';
import PerformanceDetails from '../../components/template-parts/PerformanceDetails';
import { IPerformance } from 'types/schedule';

interface Props {
	title?: string;
}

function Schedule({ title }: Props) {
	const [items, setItems] = useState<IPerformance[]>([]);
	const [currentItem, setCurrentItem] = useState<IPerformance[]>([]);

	useEffect(() => {
		const date = moment().format();
	
		const sortedData = data.allWordpressWpPerformance.edges.filter((x: any) => moment(x.node.acf.dates[0].date_time).format() > date).sort((a: any, b: any) => {
			return (new Date(a.node.acf.dates[0].date_time) as any) - (new Date(b.node.acf.dates[0].date_time) as any);
		});

		setItems(
			sortedData.map((x: any) => {
				const { node } = x;
				return (
					{
						id: node.id,
						title: node.title,
						url: `${node.acf.url}`,
						dateTime: node.acf.dates[0].date_time,
						dates: node.acf.dates,
						imgSrc: node.featured_media ? node.featured_media.localFile.childImageSharp.fluid.src : undefined
					}
				);
			}
			));
	}, []);

	const data = useStaticQuery(graphql`
        {
            allWordpressWpPerformance {
                edges {
                    node {
                        id
                        title
                        slug
                        acf {
                            dates {
                                date_time
								location
                            }
							url
                        }
						featured_media {
							localFile {
								childImageSharp {
									fluid(maxWidth: 2880, quality: 100) {
										src
									}
								}
							}
						}
                    }
                }
            }
        }
    `);

	return (
		<section id="schedule" className="schedule section--fluid" data-scroll-section data-bg-colour="brand-one">
			<div className="section-title" data-scroll data-scroll-speed="0.5" data-scroll-direction="vertical" data-scroll-delay="0.5">
				<h2>Schedule</h2>
			</div>
			<div className="container container--fluid">
				<h3 className="schedule__title">{title}</h3>
				<div className="schedule__list">
					<PerformanceList items={items} onSetCurrentItem={(x) => setCurrentItem(() => items.filter((y: IPerformance) => y.id === x))} />
					<div data-scroll data-scroll-speed="0.5" className="schedule__details">
						{currentItem.length > 0 && <PerformanceDetails currentItem={currentItem[0]} />}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Schedule;

Schedule.defaultProps = {
	title: 'Upcoming',
}
