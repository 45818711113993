import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import ExploreButton from '../ui/ExploreButton';

interface Props {
	bgImgSrc: string;
	transitionStatus: any;
}

function Hero({ bgImgSrc, transitionStatus }: any) {

	return (
		<section id="hero" className="hero" data-scroll-section data-bg-colour="home">
			<div
				className={`animate hero__bg${transitionStatus ? ` hero__bg--${transitionStatus}` : ''}`}
				style={{
					backgroundImage: `url('${bgImgSrc}')`,
				}}
			/>
			<div className="container">
				<div className="hero__title">
					<h1 data-scroll data-scroll-speed="-1">
						<span className={`line${transitionStatus ? ` line--${transitionStatus}` : ''}`}>
							<span>Roderick</span>
						</span>
						<br />
						<span className={`line${transitionStatus ? ` line--${transitionStatus}` : ''}`}>
							<span className="red">Cox</span>
						</span>
					</h1>
				</div>
			</div>
			<ExploreButton selector="#schedule" />
		</section>
	);
}

export default Hero;
