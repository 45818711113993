import React from 'react';
import Anchor from '../../components/ui/Anchor';

interface Props {
	imgScale: number;
	title: string;
	intro: string;
	pageLink?: string;
	imgSrc: string;
}

function Initiative({ imgScale, title, intro, pageLink, imgSrc }: Props) {

	return (
		<section id="rc-trust" className="initiative" data-scroll-section data-bg-colour="black">
			<div className="container">
				<div className="initiative__content" data-scroll data-scroll-speed="1">
					<h2 className="initiative__title">{title}</h2>
					<p className="initiative__intro">{intro}</p>
					{pageLink && <Anchor className="button button--red" url="/rcmi">Find out more</Anchor>}
				</div>
				<div className="initiative__image">
					<div
						className="inner"
						style={{
							backgroundImage: `url('${imgSrc}')`,
							transform: `translate(-50%, -50%) scale(${imgScale})`,
						}}
					/>
				</div>
			</div>
		</section>
	);
}

export default Initiative;
