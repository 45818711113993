import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { IPerformance } from 'types/schedule';
import Button from '../ui/Button';
import Anchor from '../ui/Anchor';

interface Props {
	items: IPerformance[];
	onSetCurrentItem: (id: string) => void;
}

function PerformanceList({ items, onSetCurrentItem }: Props) {
	const [listPos, setListPos] = useState(0);
	const lsitEl = useRef<any>(null);

	const updateListPosition = (dir: string) => {
		const { current } = lsitEl;

		if (!current) {
			return;
		}

		const elHeight = current.offsetHeight;
		const elHeightOffset = (elHeight / 2) - 50;
		const elRect = current.getBoundingClientRect();
		const elTop = elRect.top;
		const articles = current.children[0].children;
		const lastArticle = articles[articles.length - 1];
		const lastArticleRect = lastArticle.getBoundingClientRect();

		setListPos((prev: number) => {
			if (dir === 'next') {
				return (lastArticleRect.top + lastArticle.offsetHeight) < (elTop + elHeight) ? prev : prev + elHeightOffset;
			}

			return prev - elHeightOffset <= 0 ? 0 : prev - elHeightOffset;
		});
	};

	return (
		<>
			<div ref={lsitEl} className="schedule__articles">
				<div className="inner" style={{ transform: `translateY(${-Math.abs(listPos)}px)` }}>
					{items && items.map((x, i) => {

						if (i > 5) {
							return;
						}
						
						return (
							<article key={x.id} className="performance" data-scroll data-scroll-speed={i / 2}>
								<div className="performance__inner" onMouseEnter={() => onSetCurrentItem(x.id)}>
									<h3 className="performance__title">{x.title}</h3>
									{x.dates && x.dates.map((y: any, i: any) => (
										<div key={i} className="performance__slot">
											{y.date_time && <p className="performance__date">{y.date_time}</p>}
											{y.location && <address className="performance__address">
												{y.location}
											</address>}
										</div>
									))}
								</div>
							</article>
						);
					})}
				</div>

				<div className="schedule__controls schedule__controls--hidden">
					<Button className="button--white" onClick={() => updateListPosition('prev')} cursorClass="icon-up">View Prev</Button>
				</div>
				<div className="schedule__controls schedule__controls--hidden">
					<Button className="button--white" onClick={() => updateListPosition('next')} cursorClass="icon-down">View next</Button>
				</div>
				<div className="schedule__controls schedule__controls--inline">
					<Anchor className="button button--white" url="/schedule">View all</Anchor>
				</div>
			</div>
		</>
	);
}

export default PerformanceList;
