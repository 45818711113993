import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import IconButton from '../../components/ui/IconButton';

type Contact = {
	title: string;
	name: string;
	email_address: string;
	phone: string;
}

interface Props {
	title: string;
	intro: string;
	contacts: Contact[];
}

function Contact({ title, intro, contacts }: Props) {

	return (
		<section id="contact" className="contact" data-scroll-section data-bg-colour="white">
			<div className="section-title" data-scroll data-scroll-speed="0.5" data-scroll-direction="vertical" data-scroll-delay="0.5">
				<h2>Contact</h2>
			</div>
			<div className="container container--row">
				<h3 className="contact__title">{title}</h3>
				<p className="contact__intro">{intro}</p>
				<div className="contact__contacts">
					{contacts.map((x, i) => (
						<div key={x.email_address} className="contact__contact" data-scroll data-scroll-speed={i + 1}>
							<img src={require('../../images/harrisonparrott-logo.png')} alt="Harrison Parrott" />
							<p>{x.title}</p>
							<p>{x.name}</p>
							{x.phone && <p className="contact__phone">{x.phone}</p>}
							<a href={`mailto:${x.email_address}`}>Email here</a>
						</div>
					))}
				</div>
				<div className="contact__follow" data-scroll data-scroll-speed="1">
					<p>Follow me</p>
					<ul>
						<li>
							<IconButton
								className="icon-button--sm icon-button--red"
								url="https://www.facebook.com/roderickcoxconductor/"
								opensNewTab
							>
								<FaFacebookF />
							</IconButton>
						</li>
						<li>
							<IconButton
								className="icon-button--sm icon-button--red"
								url="https://www.instagram.com/roderickcoxconductor/"
								opensNewTab
							>
								<FaInstagram />
							</IconButton>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}

export default Contact;
