import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import { BsArrowLeft, BsArrowRight, BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import Button from '../../components/ui/Button';
import Container from '../../components/layout/Container';
import IconButton from '../../components/ui/IconButton';
import Anchor from '../../components/ui/Anchor';

interface MediaItem {
	type: 'image' | 'video' | 'press';
	url?: string;
	image_source?: string;
	video_source?: string;
}

function Media() {
	const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
	const [filteredMediaItems, setFilteredMediaItems] = useState<MediaItem[]>([]);
	const [currentFilter, setCurrentFilter] = useState('video');
	const [title, setTitle] = useState<string>();
	const [sliderOpen, setSliderOpen] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [listPos, setListPos] = useState(0);
	const [swiperEl, setSwiperEl] = useState<any>(null);
	const [swiperMobEl, setSwiperMobEl] = useState<any>(null);
	const lsitEl = useRef<HTMLDivElement>(null);
	const [cursor, setCursor] = useState<HTMLElement | null>(null);

	useEffect(() => {
		setCursor(document.getElementById('cursor'));
	}, []);

	const data = useStaticQuery(graphql`
		{
			allWordpressPage {
				edges {
					node {
						id
						acf {
							photo_gallery {
								localFile {
									childImageSharp {
										fluid(maxWidth: 2880, quality: 100) {
											src
										}
									}
								}
							}
							videos {
								video
								cover_image {
									localFile {
										childImageSharp {
											fluid(maxWidth: 2880, quality: 100) {
												src
											}
										}
									}
								}
							}
						}
						template
						title
					}
				}
			}
		}
    `);

	useEffect(() => setFilteredMediaItems(_.shuffle(mediaItems.filter((x) => x.type === 'video'))), [mediaItems]);

	useEffect(() => {
		data.allWordpressPage.edges
			.filter((x: any) => x.node.template === 'templates/template-media.php')
			.map((media: any) => {
				const { node: { title, acf: { photo_gallery, videos } } } = media;

				setTitle(title);

				photo_gallery.map((x: any) => setMediaItems((prev) => [...prev, {
					type: 'image',
					image_source: x.localFile.childImageSharp.fluid.src
				}]));

				videos.map((x: any) => setMediaItems((prev) => [...prev, {
					type: 'video',
					image_source: x.cover_image.localFile.childImageSharp.fluid.src,
					video_source: x.video
				}]));
			})
	}, []);

	const onFilter = (type?: string) => {
		setListPos(0);
		setFilteredMediaItems(type !== undefined ? mediaItems.filter((x) => x.type === type) : mediaItems);
		setCurrentFilter(type || 'all');
	};

	const onMediaItemEnter = () => {
		cursor?.classList.add('cursor--button', 'cursor--icon-plus');
	};

	const onMediaItemLeave = () => {
		cursor?.classList.remove('cursor--button', 'cursor--icon-plus');
	};

	const onOverlayEnter = () => {
		cursor?.classList.add('cursor--button', 'cursor--icon-cross');
	};

	const onOverlayLeave = () => {
		cursor?.classList.remove('cursor--button', 'cursor--icon-cross');
	};

	const onSlideEnter = () => {
		cursor?.classList.add('cursor--none');
	};

	const onSlideLeave = () => {
		cursor?.classList.remove('cursor--none');
	};

	const updateListPosition = (dir: string) => {
		const { current } = lsitEl;

		if (!current) {
			return;
		}

		const elHeight = current.offsetHeight;
		const elHeightOffset = window.innerHeight / 2;

		setListPos((prev: number) => {
			if (dir === 'next') {
				return prev + elHeightOffset >= elHeight ? prev : prev + elHeightOffset;
			}

			return prev - elHeightOffset <= 0 ? 0 : prev - elHeightOffset;
		});
	};

	return (
		<>
			<section id="media" className="media" data-scroll-section data-bg-colour="black">
				<div className="container container--fs-center">
					{filteredMediaItems.length > 0 && (
						<>
							<div className="media__waterfall" data-scroll data-scroll-speed="0.5" data-scroll-direction="vertical">
								<div ref={lsitEl} className="inner" style={{ transform: `translateY(${-Math.abs(listPos)}px)` }}>
									{filteredMediaItems.map((x: any, i: number) => (
										<article key={i} className={`media__article media__article--${x.type}`}>
											<div className="article-inner">
												<img
													className={`media__${x.type}`}
													src={x.image_source}
													onMouseEnter={onMediaItemEnter}
													onMouseLeave={onMediaItemLeave}
													onClick={() => {
														setSliderOpen(true);
														setCurrentSlide(i);
													}}
												/>
											</div>
										</article>
									))}
								</div>
							</div>
							<div className="media__content">
								<h2 className="media__title">{title}</h2>
								<div className="media__filters">
									<Anchor url="/press" className="button button--white media__filter">Press</Anchor>
									<Button onClick={() => onFilter('video')} className="button button--white media__filter"><span className={`${currentFilter === 'video' ? 'active' : ''}`}>Videos</span></Button>
									<Button onClick={() => onFilter('image')} className="button button--white media__filter"><span className={`${currentFilter === 'image' ? 'active' : ''}`}>Photos</span></Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={`media__controls media__controls--prev${listPos === 0 ? ' media__controls--hide' : ''}`}>
					<Button className="button--white" onClick={() => updateListPosition('prev')} cursorClass="icon-up"><BsArrowUp /></Button>
				</div>
				<div className={`media__controls media__controls--next${lsitEl.current?.offsetHeight && listPos > lsitEl.current?.offsetHeight - 400 ? ' media__controls--hide' : ''}`}>
					<Button className="button--white" onClick={() => updateListPosition('next')} cursorClass="icon-down"><BsArrowDown /></Button>
				</div>
			</section>
			{sliderOpen && mediaItems.length > 0 && (
				<div className="media-slider media-slider--desktop">
					<IconButton
						className="media-slider__arrow media-slider__arrow--prev"
						onClick={() => swiperEl === null || swiperEl.slidePrev()}
					>
						<BsArrowLeft />
					</IconButton>
					<div
						className="media-slider__slider"
						onMouseEnter={onOverlayEnter}
						onMouseLeave={onOverlayLeave}
						onClick={() => {
							cursor?.classList.remove('cursor--button', 'cursor--icon-cross');
							setSliderOpen(false);
						}}
					>
						<Container>

							<Swiper
								slidesPerView={1}
								onSwiper={(x) => {
									x.slideTo(currentSlide, 0);
									setSwiperEl(x);
								}}
							>
								{filteredMediaItems.map((x: any, i: number) => (
									<SwiperSlide
										key={i}
										onMouseEnter={onSlideEnter}
										onMouseLeave={onSlideLeave}
									>
										{x.video_source ? <div className="media-slider__video" dangerouslySetInnerHTML={{ __html: x.video_source }} /> : <img src={x.image_source} />}
									</SwiperSlide>
								))}
							</Swiper>
						</Container>
					</div>
					<IconButton
						className="media-slider__arrow media-slider__arrow--next"
						onClick={() => swiperEl === null || swiperEl.slideNext()}
					>
						<BsArrowRight />
					</IconButton>
				</div>
			)}
			<div className="media-slider media-slider--mobile">
				<IconButton
					className="media-slider__arrow media-slider__arrow--prev"
					onClick={() => swiperMobEl === null || swiperMobEl.slidePrev()}
				>
					<BsArrowLeft />
				</IconButton>
				<div
					className="media-slider__slider"
					onMouseEnter={onOverlayEnter}
					onMouseLeave={onOverlayLeave}
					onClick={() => {
						cursor?.classList.remove('cursor--button', 'cursor--icon-cross');
						setSliderOpen(false);
					}}
				>
					<Container>

						<Swiper
							slidesPerView={1}
							onSwiper={(x) => {
								setSwiperMobEl(x);
							}}
						>
							{filteredMediaItems.map((x: any, i: number) => (
								<SwiperSlide
									key={i}
									onMouseEnter={onSlideEnter}
									onMouseLeave={onSlideLeave}
								>
									{x.video_source ? <div className="media-slider__video" dangerouslySetInnerHTML={{ __html: x.video_source }} /> : <img src={x.image_source} />}
								</SwiperSlide>
							))}
						</Swiper>
					</Container>
				</div>
				<IconButton
					className="media-slider__arrow media-slider__arrow--next"
					onClick={() => swiperMobEl === null || swiperMobEl.slideNext()}
				>
					<BsArrowRight />
				</IconButton>
			</div>
		</>
	);
}

export default Media;
